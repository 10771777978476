<template>
  <v-container class="pa-4 text-center">
    <v-row>
      <v-col cols="12">
        <h1  style="font-size: 50px" class="d-none d-md-block text-center">{{title}}</h1>
        <h1  class="d-md-none text-center">{{title}}</h1>
      </v-col>
    </v-row>
    <PageText :text="p1" />
    <PageQuote :text="q1" person="Tara Brach" />
    <PageImage path="img/ss-braing-window.jpg" />
  </v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageQuote from '@/components/PageQuote.vue';
import PageImage from '@/components/PageImage.vue';

export default {
  components: { PageText, PageImage, PageQuote },
  data: () => ({
    title: 'Dialectical Behavioral Therapy',
    p1: `
      Dialectical Behavioral Therapy (DBT) has a considerable amount of overlap with Cognitive
      Behavioral Therapy. Originally created for the treatment of Borderline Personality Disorder,
      DBT has since been adapted to treat a variety of psychological issues and symptoms. DBT
      involves skills training around four topics: distress tolerance, emotion regulation,
      mindfulness, and interpersonal effectiveness. DBT emphasizes behavioral changes to alter
      difficult emotions. I use DBT often in my practice where I notice my clients may have skill
      deficits that negatively impact their mental health.
    `,
    q1: `
      “When we put down ideas of what life should be like, we are free to wholeheartedly say yes to
      our life as it is.”
    `,
  }),
};
</script>
