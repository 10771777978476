<template>
  <v-row>
    <v-col cols="2" md="4"></v-col>
    <v-col cols="8" md="4">
      <v-card flat class="grey lighten-2" >
          <p class="pl-4 pt-4 pr-4 text-body-2 text-left font-italic text-left">
            {{text}}
          </p>
          <div class="pl-4 pb-4 text-body-1 font-italic text-left">
            – {{person}}
          </div>
      </v-card>
    </v-col>
    <v-col cols="2" md="4"></v-col>
  </v-row>
</template>

<script>
export default {
  props: ['text', 'person'],
};
</script>
